<template>
  <div class="person">
    <person-table v-if="showPersons" />

    <person-profile v-else-if="showPerson" />

    <internal-error v-else />

    <person-modal v-if="isInternalAdminUser" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import mixin from '../components/mixin'

const { mapGetters: mapAuthGetters } = createNamespacedHelpers('auth')
const { mapGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'Person',
  components: {
    PersonModal: () => import('../components/PersonModal'),
    PersonProfile: () => import('../components/Person.vue'),
    PersonTable: () => import('../components/Persons.vue'),
  },
  mixins: [mixin],
  computed: {
    ...mapAuthGetters(['isInternalAdminUser']),
    ...mapGetters([
      'isReportIdValid',
    ]),
    ...mapToolsState({
      reportId: (state) => state.reportId,
    }),
    isReportIdInvalid() {
      const { isReportIdValid, reportId } = this
      return reportId && !isReportIdValid
    },
    showPersons() {
      const { isReportIdInvalid, reportId } = this
      return isReportIdInvalid || !reportId
    },
    showPerson() {
      const { isReportIdInvalid, reportId } = this
      return !isReportIdInvalid && reportId
    },
  },
}
</script>
